import TrimbleMaps, { RouteOptions } from '@trimblemaps/trimblemaps-js'

export const DEFAULT_MAP_CENTER = new TrimbleMaps.LngLat(-90.755043, 39.976473) // DOT HQ
export const ROUTE_OPTIONS: RouteOptions = {
  truckConfig: TrimbleMaps.Common.TruckConfig.FIFTY_THREE,
  bordersOpen: false,
  showStops: true, 
  highwayOnly: false,
  useAvoidsAndFavors: true,
  routeColor: 'rgba(0, 0, 255, .75)',
  frameOptions: {
    padding: {
      top: document.documentElement.clientHeight / Math.log2(document.documentElement.clientHeight),
      bottom: document.documentElement.clientHeight / Math.log2(document.documentElement.clientHeight),
      left: document.documentElement.clientHeight / Math.log2(document.documentElement.clientHeight),
      right: document.documentElement.clientHeight / Math.log2(document.documentElement.clientHeight),
    },
  },
}