import React, { Fragment } from "react";
import Box, { Item } from "devextreme-react/box";
import "./load-header.scss";
import { faClipboardList, faClipboardCheck } from '@fortawesome/pro-solid-svg-icons' //more icons at: https://fontawesome.com/icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ trailer, load, truck, driverTwoId, confirmationState }) => {

    var localDriver = driverTwoId;
    if ((driverTwoId == null) || (driverTwoId.length <= 0)) {
        localDriver = "N/A";
    }

    return (
        <Fragment>
            <Box height={90} className="rect load-header-our-load-color msg-borders">
                <Item ratio={1}>
                    <div className="center-icon load-header-right-border">
                        {confirmationState === "Unconfirmed"
                            ?
                            <FontAwesomeIcon icon={faClipboardList} className={"load-header-clipboard-red"} size={'3x'} />
                            :
                            <FontAwesomeIcon icon={faClipboardCheck} className={"load-header-clipboard-green"} size={'3x'} />
                        }
                    </div>
                </Item>
                <Item ratio={1}>
                    <div className="load-header-our-load-color load-header-weight">Load</div>
                    <div className="load-header-our-load-color load-header-weight">Truck</div>
                    <div className="load-header-our-load-color load-header-weight">Trailer</div>
                    <div className="load-header-our-load-color load-header-weight">Driver #2</div>
                </Item>
                <Item ratio={1}>
                    <Box>
                        <Item>
                            <div className="load-header-our-load-color load-header-weight">:</div>
                        </Item>
                    </Box>
                    <Box>
                        <Item>
                            <div className="load-header-our-load-color load-header-weight">:</div>
                        </Item>
                    </Box>
                    <Box>
                        <Item>
                            <div className="load-header-our-load-color load-header-weight">:</div>
                        </Item>
                    </Box>
                    <Box>
                        <Item>
                            <div className="load-header-our-load-color load-header-weight">:</div>
                        </Item>
                    </Box>
                </Item>
                <Item ratio={1}>
                    <Box>
                        <Item>
                            <div className="load-header-our-load-color load-header-weight">{load}</div>
                        </Item>
                    </Box>
                    <Box>
                        <Item>
                            <div className="load-header-our-load-color load-header-weight">{truck}</div>
                        </Item>
                    </Box>
                    <Box>
                        <Item>
                            <div className="load-header-our-load-color load-header-weight">{trailer}</div>
                        </Item>
                    </Box>
                    <Box>
                        <Item>
                            <div className="load-header-our-load-color load-header-weight">{localDriver}</div>
                        </Item>
                    </Box>
                </Item>
            </Box>
        </Fragment>
    );
};

